import React, { useState } from 'react';

const SendLMIA = () => {
    const [formData, setFormData] = useState({
        name: '',
        referenceNo: '',
        designation: '',
        companyName: '',
        link: '',
        userEmail: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Implement your logic for sending LMIA data to the backend
        // Example:
        fetch('https://job-polar-server.vercel.app/send-lmia-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('LMIA data sent successfully:', data);
                // Add any additional logic or UI updates upon successful sending
            })
            .catch((error) => {
                console.error('Error sending LMIA data:', error);
                // Handle errors, show user feedback, etc.
            });
    };

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
                <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
                    Send LMIA
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="John Doe"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Reference No
                        </label>
                        <input
                            type="text"
                            name="referenceNo"
                            value={formData.referenceNo}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="LMIA-12345"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Designation
                        </label>
                        <input
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="Job Title"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Company Name
                        </label>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="ABC Corp"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Link
                        </label>
                        <input
                            type="text"
                            name="link"
                            value={formData.link}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="LMIA Document Link"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            User Email
                        </label>
                        <input
                            type="email"
                            name="userEmail"
                            value={formData.userEmail}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 rounded border"
                            placeholder="john@example.com"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full"
                    >
                        Send LMIA
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SendLMIA;
