import React, { useState } from 'react';
import Homepage from './components/Homepage/Homepage';
import Navbar from './components/Shared/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import Messages from './components/Messages/Messages';
import SendOfferLetter from './components/Forms/SendOfferLetter';
import SendLMIA from './components/Forms/SendLMIA';

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <React.Fragment>
      <div className="">
        <Navbar toggleDrawer={toggleDrawer} />

      </div>

      <Routes>
        <Route path='/' element={<Homepage isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />}></Route>
        <Route path="/messages" element={<Messages />} />
        <Route path="/send-offer-letter" element={<SendOfferLetter />} />
        <Route path="/send-lmia" element={<SendLMIA />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
