import React, { useState } from 'react';

const SendOfferLetter = () => {
    const [formData, setFormData] = useState({
        userName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        offerLetter: '',
        startDate: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Implement your logic for sending offer letter using formData
        // You can use fetch or an API call to send the data to the server
        // Example:
        fetch('https://job-polar-server.vercel.app/send-offer-letter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Offer letter sent successfully:', data);
                // Add any additional logic or UI updates upon successful sending
            })
            .catch((error) => {
                console.error('Error sending offer letter:', error);
                // Handle errors, show user feedback, etc.
            });
    };

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
                <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
                    Send Offer Letter
                </h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            User Name
                        </label>
                        <input
                            type="text"
                            name="userName"
                            value={formData.userName}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="John Doe"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="john@example.com"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Company Name
                        </label>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="ABC Corp"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Job Title
                        </label>
                        <input
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="Software Engineer"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Start Date
                        </label>
                        <input
                            type="text"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="10-12-2024"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Offer Letter
                        </label>
                        <input
                            name="offerLetter"
                            value={formData.offerLetter}
                            onChange={handleChange}
                            className="form-input w-full py-2 px-3 border rounded"
                            placeholder="Input your offer letter downloadable links here..."
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline w-full"
                    >
                        Send Offer Letter
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SendOfferLetter;
