/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const Messages = () => {
    const [selectedSender, setSelectedSender] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const scrollContainerRef = useRef();
    const queryClient = useQueryClient();

    const messagesQuery = useQuery('messages', async () => {
        const response = await fetch('https://job-polar-server.vercel.app/messages');
        const data = await response.json();
        return data;
    });

    const deleteMessageMutation = useMutation(
        (id) =>
            fetch(`https://job-polar-server.vercel.app/message/delete/${id}`, {
                method: 'DELETE',
                'Content-Type': 'application/json',
            }),
        {
            onSuccess: () => {
                // Invalidate and refetch the messages query after successful deletion
                queryClient.invalidateQueries('messages');
            },
        }
    );

    useEffect(() => {
        // Scroll to the bottom when messages or selectedSender changes
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [messagesQuery.data, selectedSender]);

    const handleSenderClick = (jobId) => {
        setSelectedSender(jobId);
    };

    const handleSendMessage = () => {
        // Define id and user
        const id = selectedSender;

        // Implement logic to send a message
        console.log(`Sending message: ${inputMessage}`);

        fetch('https://job-polar-server.vercel.app/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                jobId: id,
                sender: 'Admin',
                user: false,
                content: inputMessage, // Use inputMessage instead of newMessage
                time: new Date(),
                isRead: false,
            }),
        });
        setInputMessage('');
    };

    const handleClickOnMore = (id) => {
        deleteMessageMutation.mutate(id);
        console.log(id);
    };
    return (
        <div className="flex flex-col lg:flex-row h-full">
            {/* List of message senders */}
            <div className="lg:w-1/4 p-4">
                <h2 className="text-2xl font-bold mb-4">Message Senders</h2>
                <ul className="space-y-2">
                    {messagesQuery.data &&
                        Object.keys(messagesQuery.data).map((jobId) => (
                            <li
                                key={jobId}
                                className={`cursor-pointer p-2 rounded ${selectedSender === jobId ? 'bg-gray-200' : ''}`}
                                onClick={() => handleSenderClick(jobId)}
                            >
                                {`Job_ID_${jobId}`}
                            </li>
                        ))}
                </ul>
            </div>

            {/* Display message box */}
            <div className="lg:w-3/4 p-4 bg-[#F3F7FB] flex-1 h-[800px] overflow-y-auto" ref={scrollContainerRef}>
                {selectedSender ? (
                    <div className="flex flex-col h-full">
                        <h2 className="text-xl font-bold mb-2">{`Sender ${selectedSender}`}</h2>
                        <div className="flex-1">
                            {messagesQuery.data &&
                                messagesQuery.data[selectedSender]?.map((message, index) => (
                                    <div className={message?.user ? 'chat chat-start' : 'chat chat-end'} key={index + 1}>
                                        <div className="flex justify-center items-center gap-5">
                                            <div className="chat-bubble">{message?.content}</div>
                                            {message?.user && (
                                                <div className="font-semibold">
                                                    <div className="dropdown dropdown-bottom">
                                                        <div tabIndex={0} role="button" className="btn m-1 btn-sm">
                                                            :
                                                        </div>
                                                        <ul
                                                            tabIndex={0}
                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                                                        >
                                                            <li onClick={() => handleClickOnMore(message?.id)}>
                                                                <a>Delete Message</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}

                                            {!message?.user && (
                                                <div className="font-semibold">
                                                    <div className="dropdown  dropdown-left">
                                                        <div tabIndex={0} role="button" className="btn m-1 btn-sm">
                                                            :
                                                        </div>
                                                        <ul
                                                            tabIndex={0}
                                                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                                                        >
                                                            <li onClick={() => handleClickOnMore(message?.id)}>
                                                                <a>Delete Message</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="flex items-center mt-4">
                            <input
                                type="text"
                                placeholder="Type your message..."
                                className="border p-2 w-full"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSendMessage();
                                    }
                                }}
                            />
                            <button
                                className="bg-blue-500 text-white px-4 py-2 ml-2 rounded-md"
                                onClick={handleSendMessage}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-500">Select a message sender to view messages.</p>
                )}
            </div>
        </div>
    );
};

export default Messages;