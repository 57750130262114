// src/components/HomePage.jsx
import React from 'react';

const Homepage = ({ isDrawerOpen, toggleDrawer }) => {
    return (
        <div className='bg-[#F3F7FB]'>
            <div className={`drawer ${isDrawerOpen ? 'drawer-open' : ''}`}>
                <input id="my-drawer" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content my-12">
                    {/* Page content here */}
                    <div className="p-6">
                        <h2 className="text-2xl font-bold mb-4">Welcome to Admin Dashboard</h2>
                        <p className="text-gray-600">Explore and manage your admin tasks.</p>

                        {/* Cards for different sections */}
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 my-16">
                            {/* Manage User Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Manage Users</h3>
                                <p className="text-gray-600">View and manage user information.</p>
                                <a href="/users" className="text-blue-500 hover:underline">Go to Users</a>
                            </div>

                            {/* Manage Payment Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Manage Payments</h3>
                                <p className="text-gray-600">Review and process payment details.</p>
                                <a href="/payments" className="text-blue-500 hover:underline">Go to Payments</a>
                            </div>

                            {/* Messages Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Messages</h3>
                                <p className="text-gray-600">Check and respond to user messages.</p>
                                <a href="/messages" className="text-blue-500 hover:underline">Go to Messages</a>
                            </div>


                            {/* Send email Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Send Job Confirmation Email</h3>
                                <p className="text-gray-600">Check and respond to user Application.</p>
                                <a href="/messages" className="text-blue-500 hover:underline">Go to Pending Applications</a>
                            </div>


                            {/* Send email Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Send Offer Letter</h3>
                                <p className="text-gray-600">Send email to the applicant with their Offer Letter.</p>
                                <a href="/send-offer-letter" className="text-blue-500 hover:underline">Go to Send Offer Letter</a>
                            </div>


                            {/* Send email Card */}
                            <div className="p-4 bg-white rounded-md ">
                                <h3 className="text-xl font-bold mb-2">Send LMIA (Labour Market Impact Assessment)</h3>
                                <p className="text-gray-600">Check and respond to user Application.</p>
                                <a href="/send-lmia" className="text-blue-500 hover:underline">Go to Send LMIA</a>
                            </div>

                            {/* Add more cards as needed */}
                        </div>
                    </div>
                </div>
                <div className="drawer-side">
                    <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay" onClick={() => toggleDrawer(false)}></label>
                    <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
                        {/* Sidebar content here */}
                        <li>
                            <a href='/home'>Homepage</a>
                        </li>
                        <li>
                            <a href='/users'>Manage User</a>
                        </li>
                        <li>
                            <a href='/messages'>Messages</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
